:root {
  --toastify-color-light: #1a2a2a;
  --toastify-color-text: #ffffff;
  --toastify-color-info: #a2a2a2;
  --toastify-color-success: #5acdb1;
  --toastify-color-warning: #f2c94c;
  --toastify-color-error: #cd4d5c;
}

.Toastify__toast-container {
  @media only screen and (max-width: 480px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.Toastify__toast-container--top-center {
      top: 1em;
      left: 50%;
      transform: translateX(-50%);
      width: 320px;
    }
  }
}

.Toastify__toast {
  min-height: 56px !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 20px;
  color: var(--toastify-color-text);
  padding: 16px;
  width: 300px;

  @media only screen and (max-width: 480px) {
    margin-bottom: 0.5rem;
  }

  svg {
    width: 22px !important;
  }

  &-body {
    padding: 0 !important;
  }

  &-icon {
    width: fit-content !important;
  }

  &--success {
    background-color: var(--toastify-color-success);
  }

  &--info {
    background-color: var(--toastify-color-info);
  }

  &--warning {
    background-color: var(--toastify-color-warning);
  }

  &--error {
    background-color: var(--toastify-color-error);
  }

  &--default {
  }
}

.Toastify__close-button {
  align-self: center;
  width: 24px;
  height: 24px;
  opacity: 1;

  &:hover {
    opacity: 0.3;
  }
}
.Toastify__toast-icon {
  margin-right: 16px;
}
