@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == 'woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    } @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@include font-face('Alpha Lyrae', '../../shared/assets/fonts/AlphaLyrae-Medium', 500, normal, 'ttf');
@include font-face('Inter', '../../shared/assets/fonts/Inter-Regular', 400, normal, 'ttf');
@include font-face('Inter', '../../shared/assets/fonts/Inter-Medium', 500, medium, 'ttf');
@include font-face('Chakra Petch', '../../shared/assets/fonts/ChakraPetch-Regular', 400, normal, 'ttf');
@include font-face('Chakra Petch', '../../shared/assets/fonts/ChakraPetch-Medium', 500, medium, 'ttf');
@include font-face('Chakra Petch', '../../shared/assets/fonts/ChakraPetch-SemiBold', 600, bold, 'ttf');
@include font-face('Redaction 20', '../../shared/assets/fonts/Redaction_20-Italic', 400, normal, 'woff2');
